import React from "react"
import SEO from "../components/SEO"
import ButtonCustom from "../components/ButtonCustom"

import { navigate } from "gatsby"

const ErrorPage = () => (
  <>
    <SEO title="Error" description="Something went wrong" />
    <div>
      <div className={`body-copy wrapper wrapper--small`}>
        Sorry, something went wrong. We'll fix it as soon as we can!
      </div>
      <ButtonCustom
        size="large"
        hierarchy="primary"
        role="button"
        handleClick={() => navigate("/")}
      >
        Back to home
      </ButtonCustom>
    </div>
  </>
)

export default ErrorPage
